import React, { createContext, useEffect, useState } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, TestERCContract } from "src/constants";
import {
  getBalanceOf,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
  swichNetworkHandler,
} from "src/utils";
import { CryptoChipsContract } from "src/constants";
import CryptoChipsABI from "src/ABI/CryptoChipsABI.json";
import axios from "axios";
import TestERCABI from "src/ABI/TestERCABI.json";
import { toast } from "react-toastify";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, active, account, library, deactivate, chainId } =
    useWeb3React();
  const [nftPrice, setNftPrice] = useState(0);
  const [hasFinalSaleStarted, setHasFinalSaleStarted] = useState(false);
  const [hasPreSaleStarted, setHasPreSaleStarted] = useState(false);
  const [reservedClaimed, setReservedClaimed] = useState(1);
  const [RESERVED_NFT, setRESERVED_NFT] = useState(1);
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [MAX_MINT, setMAX_MINT] = useState(10);
  const [MAX_NFT_WALLET, setMAX_NFT_WALLET] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [publicSaleStartTimestamp, setPublicSaleStartTimestamp] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [allNftList, setallNftList] = useState([]);
  const [userNFTList, setUserNFTList] = useState([]);
  const [accumulated, setAccumulated] = useState(0);
  const [isLoadingUserNFT, setIsLoadingUserNFT] = useState(false);
  const [NAME_CHANGE_PRICE, setNAME_CHANGE_PRICE] = useState(0);
  let data = {
    nftPrice,
    NAME_CHANGE_PRICE,
    isLoadingUserNFT,
    accumulated,
    balanceOfValue,
    adminWalletAddress,
    reservedClaimed,
    MAX_NFT_SUPPLY,
    totalSupply,
    RESERVED_NFT,
    MAX_MINT,
    MAX_NFT_WALLET,
    hasFinalSaleStarted,
    hasPreSaleStarted,
    publicSaleStartTimestamp,
    userNFTList,
    // getContractDetai,
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
    getContractDetails: () => getContractDetails(),
    userNFTListHadler: (balanceOf) => userNFTListHadler(balanceOf),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: () => {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          toast.warn(error.message);
          activate(injected);
        }
      });
    },
  };

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    console.log("cancelTokenSource", cancelTokenSource);
    setUserNFTList([]);
    setAccumulated(0);
    const contract = getContract(
      CryptoChipsContract,
      CryptoChipsABI,
      library,
      account
    );
    console.log("contract", contract);
    const testERCcontract = getContract(
      TestERCContract,
      TestERCABI,
      library,
      account
    );

    const web3 = await getWeb3Obj();
    let accumulatedSum = 0;
    try {
      setIsLoadingUserNFT(true);
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        let accumulatedAmt = await testERCcontract.accumulated(id.toString());
        accumulatedSum += parseFloat(
          web3.utils.fromWei(accumulatedAmt.toString())
        );
        const name = await contract.tokenNameByIndex(id.toString());
        const filter = await contract.tokenURI(id.toString());
        try {
          const res = await axios.get(filter, {
            cancelToken: cancelTokenSource && cancelTokenSource.token,
          });
          if (res.status === 200) {
            setUserNFTList((prev) => [
              ...prev,
              {
                id: id.toString(),
                accumulatedAmt: web3.utils.fromWei(accumulatedAmt.toString()),
                name: name,
                nfdData: res.data,
              },
            ]);
          }
        } catch (error) {
          console.log("ERROR", error);
        }
      }
      setIsLoadingUserNFT(false);
      setAccumulated(parseFloat(accumulatedSum));
    } catch (e) {
      setIsLoadingUserNFT(false);
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account, chainId]);

  const allNFTListHandler = async (txaCount) => {
    const contract = await getWeb3ContractObject(
      CryptoChipsABI,
      CryptoChipsContract
    );
    try {
      for (let i = 1; i <= parseInt(txaCount); i++) {
        const tokenURI = await contract.methods.tokenURI(i.toString()).call();
        const res = await axios.get(tokenURI);
        if (res.status === 200) {
          setallNftList((prev) => [
            ...prev,
            { id: i.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (error) {
      setallNftList([]);
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (totalSupply > 0) {
      // allNFTListHandler(totalSupply);
    }
  }, [totalSupply]);

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        CryptoChipsABI,
        CryptoChipsContract
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      const hasFinalSaleStarted = await contractObj.methods
        .publicSaleActive()
        .call();
      setHasFinalSaleStarted(hasFinalSaleStarted);
      const hasPreSaleStarted = await contractObj.methods
        .preSaleActive()
        .call();
      setHasPreSaleStarted(hasPreSaleStarted);

      // const publicSaleStartTimestamp = await contractObj.methods
      //   .publicSaleStartTimestamp()
      //   .call();
      // setPublicSaleStartTimestamp(publicSaleStartTimestamp);
      // console.log(
      //   "publicSaleStartTimestamp",
      //   new Date(publicSaleStartTimestamp * 1000)
      // );
      // const MAX_MINT = await contractObj.methods.MAX_MINT().call();
      // setMAX_MINT(Number(MAX_MINT));

      const NAME_CHANGE_PRICE = await contractObj.methods
        .NAME_CHANGE_PRICE()
        .call();
      console.log(
        "NAME_CHANGE_PRICE",
        web3.utils.fromWei(NAME_CHANGE_PRICE.toString())
      );
      setNAME_CHANGE_PRICE(
        parseFloat(web3.utils.fromWei(NAME_CHANGE_PRICE.toString()))
      );

      const reservedClaimed = await contractObj.methods.presaleClaimed().call();

      const RESERVED_NFT = await contractObj.methods.PRESALE_NFT().call();
      setReservedClaimed(reservedClaimed);
      setRESERVED_NFT(RESERVED_NFT);
      // console.log("RESERVED_NFT",RESERVED_NFT);
      if (reservedClaimed != RESERVED_NFT && !hasFinalSaleStarted) {
        const RESERVE_PRICE = await contractObj.methods.PRESALE_PRICE().call();
        const getRESERVE_PRICE = await web3.utils.fromWei(
          RESERVE_PRICE.toString()
        );
        setNftPrice(getRESERVE_PRICE);
        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET_PRESALE()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      } else {
        const NFT_PRICE = await contractObj.methods.NFT_PRICE().call();
        const getNFTPrice = await web3.utils.fromWei(NFT_PRICE.toString());
        setNftPrice(getNFTPrice);
        const MAX_NFT_WALLET = await contractObj.methods
          .MAX_NFT_WALLET()
          .call();
        setMAX_NFT_WALLET(Number(MAX_NFT_WALLET));
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account]);

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(CryptoChipsABI, CryptoChipsContract, account)
    );
  }

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      CryptoChipsABI,
      CryptoChipsContract
    );
    if (account) {
      getBalanceOfFun();
    }
    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
    console.log("contractObj", contractObj.methods);
  };

  useEffect(() => {
    getContractDetails();
    getCurrentMintingDetails();
  }, []);

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (!account) {
      deactivate();
    }
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
