import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Link,
} from "@material-ui/core";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    borderTop: "2px solid #d619c7",
    backgroundColor: "#000a25",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& img": {
      [theme.breakpoints.down("xs")]: {
        width: "20px",
      },
    },
    "& p": {
      marginBottom: "0px",
      paddingBottom: "20px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      textAlign: "center",
      color: "#9e9e9e",
    },
    "& svg": {
      color: "#fff",
      fontSize: "30px",
      margin: "0 10px",
      [theme.breakpoints.down("xs")]: {
        fontSize: " 20px",
        marginLeft: "10px",
      },
      "&:hover": {
        color: "#d619c7",
        textDecoration: "none",
      },
    },
  },
  centerText: {
    textAlign: "center",
  },
  rightText: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <Box className={classes.footerSection}>
      <Container maxWidth="lg">
        <Box align="center">
          <Link target="_blank" href="https://discord.gg/YZpk74EJwS">
            {" "}
            <SiDiscord />
          </Link>
          <Link target="_blank" href="https://twitter.com/MetaKnightsNFT">
            {" "}
            <FaTwitter />
          </Link>
          <Link
            target="_blank"
            href="https://www.instagram.com/metaknightsnft/"
          >
            {" "}
            <FaInstagram />
          </Link>
          <Link
            target="_blank"
            href="https://opensea.io/collection/metaknightsnft"
          >
            {" "}
            <img src="/images/opensea.svg" width={28} style={{margin: "0 10px"}} alt="opensea" />
          </Link>
        </Box>
      </Container>
      <p> Copyright @2021</p>
    </Box>
  );
}
