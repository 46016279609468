// export const NetworkContextName = "Kovan Testnet";
export const NetworkContextName = "Ethereum Mainnet";
export const ACTIVE_NETWORK = 1;
export const CryptoChipsContract = "0xdD760D674Af15a603bAE20B63edB3102Efbd4FEc";
export const TestERCContract = "0xfdf0415632cf53fdd877d39f020a7e4f7316b92f";

export const baseURI =
  "https://ipfs.io/ipfs/QmdP6VwefyuVTz3k7n2AcPv16HxJ3fz3zN23vAkJY9S6ST";

// export const RPC_URL =
//   "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
export const RPC_URL =
  "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

// export const NetworkDetails = [
//   {
//     chainId: "42",
//     chainName: "Kovan Testnet",
//     nativeCurrency: {
//       name: "Kovan Testnet",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     rpcUrls: ["https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
//     blockExplorerUrls: ["https://etherscan.io"],
//   },
// ];
export const NetworkDetails = [
  {
    chainId: "0x1",
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "Ethereum Mainnet",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
    blockExplorerUrls: ["https://etherscan.io"],
  },
];
